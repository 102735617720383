import React from "react";
import { createTheme, ThemeProvider } from "@mui/material";
// import { GoogleOAuthProvider } from "@react-oauth/google";
import ReactDOM from "react-dom/client";
import App from "./App";
// import { GOOGLE_API_KEY } from "./constants/constants";

const theme = createTheme({
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: "Poppins",
        },
      },
    },
    MuiBox: {
      styleOverrides: {
        root: {
          fontFamily: "Poppins",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: "Poppins",
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontFamily: "Poppins",
          fontSize: "12px",
        },
      },
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      {/* <GoogleOAuthProvider clientId={GOOGLE_API_KEY}> */}
        <App />
      {/* </GoogleOAuthProvider> */}
    </ThemeProvider>
  </React.StrictMode>
);
